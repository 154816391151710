import {
    GET_ADMIN_CONVERSATION_LIST,
    GET_ADMIN_CONVERSATION,
    MARK_ADMIN_CONVERSATION_AS_READ
} from '../actions/admin_conversations_action';

function AdminConversationReducers(state = {
    get_admin_conversation_list: [],
    get_admin_conversation: [],
    mark_conversation_as_read: []
}, action) {

    switch (action.type) {
        case GET_ADMIN_CONVERSATION_LIST:
            return Object.assign({}, state, {
                get_admin_conversation_list: action.get_admin_conversation_list,
            });

        case GET_ADMIN_CONVERSATION:
            return Object.assign({}, state, {
                get_admin_conversation: action.get_admin_conversation,
            });

        case MARK_ADMIN_CONVERSATION_AS_READ:
            return Object.assign({}, state, {
                mark_conversation_as_read: action.mark_conversation_as_read,
            });
        
        default:
            return state;
    }
}
    
export default AdminConversationReducers;