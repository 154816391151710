import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout, FloatButton } from "antd";
import TopbarUser from "./topbarUser";
import TopbarWrapper from "./topbar.style";
import { WebMenu } from "./topbarUser";
import Logo from "../../components/utility/logo";
import { GetAllCountries, GetAllCategories, } from "../../actions/actions";
import { checkPageAccess } from "../../components/CommonFunctions";
// import MobileMenu from "./MobileMenu";
import VoiceCall from '../../components/VoiceCall';
import './style.css';
import { getTwillioNumbersList } from "../../actions/voice_call_actions";
import SendAlert from "../SendAlert";

const test_mode = true

const { Header } = Layout;
class Topbar extends Component {

  componentDidMount() {
    this.props.dispatch(GetAllCountries());
    this.props.dispatch(GetAllCategories());
    this.props.dispatch(getTwillioNumbersList())
  }

  componentDidUpdate (prevProps, prevState) {
    // console.log("STATE topbar:---", this.state);
    // console.log("PROPS topbar:---", this.props);
  }

  render() {
    const { login_user_data } = this.props;
    const collapsed = this.props.collapsed && !this.props.openDrawer;
    const styling = {
      // background: customizedTheme.backgroundColor,
      background: "#001529",
      position: "fixed",
      width: "100vw",
      height: 70
    };
    const otpRoutes = checkPageAccess(login_user_data?.email)  || test_mode == true  ?  {
      path: "/otp-report",
      name: "OTP Report",
      type : 'dropmenu',
      module_code: 'otp-report'
    } : {};

    const routes = [
      {
        path: "/dashboard",
        name: "Dashboard",
        type: 'all',
        module_code: 'dashboard'
      },
      {
        path: "/sellerlist",
        name: "Manage Seller",
        type: 'webmenu',
        module_code: 'seller'
      },
      {
        path: "/buyerlist",
        name: "Manage Buyers",
        type: 'webmenu',
        module_code: 'buyer'
      },
      {
        path: "/orderlist",
        name: "Manage Orders",
        type: 'webmenu',
        module_code: 'order'
      },
      {
        path: "/payments",
        name: "Payments 360",
        type: 'webmenu',
        module_code: 'payment'
      },
      {
        path:"/seller-detail",
        name: "Seller 360",
        type : 'webmenu',
        module_code: 'seller-detail'
      },
      {
        path: "/jobslist",
        name: "Jobs List",
        type: 'webmenu',
        module_code: 'jobslist'
      },
      {
        path: "/conversations",
        name: "Conversation",
        type: 'webmenu',
        module_code: 'conversations'
      },
      // {
      //   path: "/buyer-conversation",
      //   name: "Buyer Conversation",
      //   type: 'dropmenu',
      //   module_code: 'buyer-conversation'
      // },
      {
        path: "/search-page-content",
        name: "Search Page Content",
        type: 'dropmenu',
        module_code: 'search-page-content'
      },
      {
        path: "/geo-location",
        name: "Geo Location",
        type : 'dropmenu',
        module_code: 'geo-location'
      },
      {
        path: "/seller-resequence",
        name: "Resequence Seller",
        type: 'dropmenu',
        module_code: 'seller-resequence'
      },
      {
        path: "/reporting",
        name: "Reporting",
        type: 'dropmenu',
        module_code: 'reporting'
      },
      {
        path: "/filter-logs",
        name: "Filter Logs",
        type: 'dropmenu',
        module_code: 'filter_logs'
      },
      {
        path: "/order-export",
        name: "Order Export",
        type: 'dropmenu',
        module_code: 'order-export'
      },
      {
        path: "/review-list",
        name: "Review List",
        type: 'dropmenu',
        module_code: 'review-list'
      },
      {
        path: "/quote-calc-logs",
        name: "Quote Calculator Logs",
        type: 'dropmenu',
        module_code: 'quote-calc-logs'
      },
      {
        path: "/tags",
        name: "Tags",
        type: 'dropmenu',
        module_code: 'tags'
      },
      {
        path: "/order-recording-list",
        name: "Order Recording List",
        type: 'dropmenu',
        module_code: 'order-recording-list'
      },
      {
        path: "/task-dashboard",
        name: "Task Dashboard",
        type: 'dropmenu',
        module_code: 'task-dashboard'
      },
      {
        path: "/order-recording-list",
        name: "Order Recording List",
        type: 'dropmenu',
        module_code: 'order-recording-list'
      },
      {
        path: "/not-interested-order-list",
        name: "Not Interested order List",
        type: 'dropmenu',
        module_code: 'not-interested-order-list'
      },
      {
        path: "/flag-order-details-list",
        name: "Flag Order Details List",
        type: 'dropmenu',
        module_code: 'flag-order-details-list'
      },
      {
        path: "/email-template-list",
        name: "Email Template List",
        type: 'dropmenu',
        module_code: 'email-template-list'
      },
      {
        path: "/deleted-artist-list",
        name: "Deleted Artist List",
        type: 'dropmenu',
        module_code: 'deleted-artist-list'
      },
      {
        path: "/spam-order-job-list",
        name: "Spam Order Job List",
        type: 'dropmenu',
        module_code: 'spam-order-job-list'
      },
      // {
      //   path: "/print-download",
      //   name: "print-download",
      //   type : 'webmenu',
      //   module_code: 'print-download'
      // },

      // {
      //   path: "/seller-progress-list",
      //   name: "seller-progress-list",
      //   type : 'webmenu',
      //   module_code: 'seller-progress-list'
      // },
      {
        path: "/city-content",
        name: "City Content",
        type: 'dropmenu',
        module_code: 'city-content'
      },
      {
        path: "/captured-email",
        name: "captured-email",
        type: 'dropmenu',
        module_code: 'View Captured Email'
      },
      {
        path: "/coupon-list",
        name: "Coupon List",
        type : 'dropmenu',
        module_code: 'Coupon List'
      },
      {
        path:"/hold-payment-list",
        name: "Hold payment List",
        type : 'dropmenu',
        module_code: 'Hold Payment List'
      },
      {
        path:"/subscription-list",
        name: "Subscription List",
        type : 'dropmenu',
        module_code: 'Subscription List'
      },
      {
        path: "/search-term-pages",
        name: "Search Term Pages",
        type : 'dropmenu',
        module_code: 'search-term-pages'
      },
      {
        path: "/otp-report",
        name: "OTP Report",
        type : 'dropmenu',
        module_code: 'otp-report'
      },
      {
        path:"/gift-card-list",
        name: "Gift Card List",
        type : 'dropmenu',
        module_code: 'Gift Card List'
      },
      {
        path:"/referral-user-list",
        name: "Referral List",
        type : 'dropmenu',
        module_code: 'Referral List'
      },
      {
        path:"/seller-count-by-country",
        name: "Seller Count By Country ",
        type : 'dropmenu',
        module_code: 'Seller Count With City'
      },
      {
        path:"/badge-list",
        name: "Badge List",
        type : 'dropmenu',
        module_code: 'Badge List'
      },
      {
        path:"/buy-art-list",
        name: "Buy Art List",
        type : 'dropmenu',
        module_code: 'Buy Art List'
      },
      // {
      //   path:"/new-job-list",
      //   name: "New Job List",
      //   type : 'dropmenu',
      //   module_code: 'new-job-list'
      // },
    ];

    const menuList = routes.filter(m => {
      const role = login_user_data && login_user_data.modules && login_user_data.modules.filter(r => r.module_code === m.module_code)[0]
      return (role && role.read === '1') || login_user_data.full_access === '1' || m.module_code === 'dashboard'
    })

    return (
      <TopbarWrapper>
        <Header
          style={styling}
          className={
            collapsed ? "isomorphicTopbar collapsed" : "isomorphicTopbar"
          }
        >
          <div className="isoLeft">
            <Logo />
          </div>
          <ul className="isoRight">
            {window.innerWidth > 1200 ?
              <div className="web-view">
                <WebMenu menuList={menuList.filter(m => m.type=== 'webmenu' || m.type === 'all')} />
              </div>
            : ""}
            <li
              onClick={() => this.setState({ selectedItem: "user" })}
              className="isoUser"
            >
              <TopbarUser menuList={window.innerWidth > 1200 ? menuList.filter(m => m.type === 'dropmenu' || m.type === 'all') : menuList} />
            </li>
          </ul>
        </Header>
        <VoiceCall />
        {
          this.props.show_send_alert_popup ? 
          <SendAlert /> 
          : null
        }
      </TopbarWrapper>
    );
  }
}

function mapStateToProps(state) {
  const { Common, VoiceCall} = state;
  const {
    login_user_data
  } = Common;

  const {
    show_send_alert_popup
  } =  VoiceCall

  return {
    login_user_data,
    show_send_alert_popup
  };
}

const AppContainer = connect(mapStateToProps)(Topbar);
export default AppContainer;